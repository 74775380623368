@if (showEntireModule()) {
  <div [hidden]="show()" cdkDrag (cdkDragStarted)="dragging$.next(true)" (cdkDragEnded)="draggingEnd()">
    <button
      mat-icon-button
      class="uh-icon"
      [matBadge]="totalPendingCounts()"
      [style.backgroundColor]="bgColor()"
      (click)="dragging() ? null : service.toggle()">
      <i class="fa fa-info"></i>
    </button>
  </div>

  <div [hidden]="!show()" cdkDrag>
    <div
      class="uh-cont glass"
      [style.backgroundColor]="bgColor()"
      [class.is-mobile]="mobileQuery.matches"
      [ngClass]="containerClassObj()">
      <mat-sidenav-container class="">
        <mat-sidenav
          #snav
          position="end"
          [opened]="true"
          [mode]="mobileQuery.matches ? 'over' : 'side'"
          [fixedInViewport]="mobileQuery.matches">
          <div class="header-cont d-flex align-items-center gap-2">
            <hr class="header-hr w-100" />
            <button mat-icon-button cdkDragHandle class="dragger">
              <mat-icon class="text-light">drag_handle</mat-icon>
            </button>
            <button mat-icon-button (click)="service.toggle()">
              <mat-icon class="text-light">close</mat-icon>
            </button>
          </div>
          <mat-nav-list>
            @for (nav of modules(); track nav.id) {
              <a
                mat-list-item
                (click)="selectModule(nav)"
                [ngClass]="{ 'active-m': nav.id == selectedModule()?.id }">
                <span class="d-flex align-items-center gap-2">
                  <i class="{{ nav.icon }}"></i>
                  {{ nav.label }}
                </span>
              </a>
            }
          </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content [hidden]="!selectedModule()">
          <div class="h-100 w-100" [PSBox]>
            @if (selectedModule(); as selectedModule) {
              @if (selectedModule.component) {
                <ng-container *ngComponentOutlet="selectedModule.component"></ng-container>
              } @else if (selectedModule.id == EUserHelperID.help) {
                <user-help-help />
              } @else if (selectedModule.id == EUserHelperID.chat) {
                <user-help-chat />
              } @else if (selectedModule.id == EUserHelperID.note) {
                <user-help-notes />
              } @else if (selectedModule.id == EUserHelperID.task) {
                <user-help-task />
              }
            }
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
}
