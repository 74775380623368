import { Component, computed, inject, input } from '@angular/core';
import CMSResourceExplorerComponent from '@Reusables/reusable-pages/cms/cms-resource/cms-resource-explorer/cms-resource-explorer.component';
import { AdminUserService } from '../Services/admin-user.service';
import {
  ECMSContentRefCat,
  ECMSModuleType,
  ICMSReference
} from '@Reusables/reusable-pages/cms/cms.model';
import { configMainMenu } from '../configs/menu-configs/top-menu.config';
import { CMSResourceExoplorerParentBaseComponent } from '@Reusables/reusable-pages/cms/cms-resource/cms-resource-explorer/cms-resource-exoplorer-parent-base/cms-resource-exoplorer-parent-base.component';

@Component({
  selector: 'admin-app-admin-help',
  imports: [CMSResourceExplorerComponent],
  templateUrl: './admin-help.component.html',
  styleUrl: './admin-help.component.scss',
})
export default class AdminHelpComponent extends CMSResourceExoplorerParentBaseComponent{
  readonly userService = inject(AdminUserService);
  
  readonly configMainMenu = configMainMenu;
  // readonly configMainMenu = getConfigMainMenu();
  readonly applicationCode = computed(() => 'JP');
  readonly baseQuery = computed<Partial<ICMSReference>>(() => ({
    applicationCode: this.applicationCode(),
    refCat: ECMSContentRefCat.path,
    moduleType: ECMSModuleType.help,
  }));

  ngOnInit(): void {
    this.userService.getFromLocal();
  }

  readonly allowEdit = computed<boolean>(() => {
    const user = this.userService.user();
    // return false;
    return (
      user?.email &&
      (user.email.startsWith('ahmed@') ||
        user.email.startsWith('osagie@') ||
        user.email.startsWith('janet@') ||
        user.email.startsWith('linda@'))
    );
  });
}
